@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.concessContainer {
  margin-top: 60px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: $primary;
    display: flex;
    justify-content: center;
  }
  .first {
    padding: 20px;
    border: 1px solid rgb(211, 211, 211);
  }
  .second {
    padding: 20px;
    border: 1px solid rgb(211, 211, 211);
  }
}
.searchContainer {
  margin: 20px 0 50px 0;
  .header {
    color: $primary;
    font-size: 24px;
    font-weight: bold;
    margin: 40px;
  }
  .angilal {
    width: 250px;
    background-color: rgb(245, 245, 245);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .angilalOptions {
      width: 120px;
    }
  }
  .filterContainer {
    background-color: rgb(245, 245, 245);
    width: 250px;
    .subHeader {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      height: 48px;
      background-color: $primary;
      align-items: center;
      color: white;
    }
    .filter {
      padding: 20px;
      .subFilter {
        margin-bottom: 15px;
      }
      .row {
        margin-top: 15px;
      }
      .rate {
        margin-bottom: 15px;
        :global {
          font-size: 14px;
          .ant-rate-star-full .ant-rate-star-second {
            background-color: $primary;
            width: 30px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            color: white;
          }
          .ant-rate-star-second {
            background-color: rgb(236, 236, 236);
            color: black;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            width: 30px;
          }
        }
      }
      .btnClear {
        width: 100px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        border: 2px solid $primary;
        color: $primary;
      }
      .btnFilter {
        width: 100px;
        display: flex;
        justify-content: space-around;
        border-radius: 10px;
        border: 2px solid $primary;
      }
    }
  }
  .results {
    background-color: rgb(250, 250, 250);
    padding: 40px 20px;
    overflow-y: scroll;
    height: 100vh;
    .result {
      margin-bottom: 20px;
      padding: 20px 40px;
      background-color: white;
      box-shadow: 1px 1px 5px rgba(214, 213, 213, 0.6);
      .concessName {
        color: $primary;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .concessDetail {
        display: flex;
        align-items: center;
        .detail {
          margin-left: 10px;
          margin-top: 4px;
        }
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }
}
.options {
  width: 100%;
}
.resultContainer {
  .name {
    margin: 30px 0;
    color: $primary;
    font-weight: bold;
    font-size: 24px;
  }
  .title {
    color: $primary;
    font-weight: bold;
  }
  .title2 {
    color: $primary;
    font-weight: bold;
    margin: 30px 0 15px 0;
  }
  .subContainer {
    margin-top: 30px;
    padding: 20px;
    background-color: rgb(243, 243, 243);
  }
  .progress {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid rgb(230, 230, 230);
    :global {
      .ant-slider-disabled .ant-slider-track {
        background-color: $normal-blue1 !important;
      }
      .ant-slider {
        cursor: default !important;
      }
      .ant-slider-disabled .ant-slider-handle {
        cursor: default !important;
        background-color: $normal-blue1;
        border: none;
      }
      .ant-slider-disabled .ant-slider-dot {
        cursor: default !important;
        background-color: $normal-blue1;
        border: none;
      }
      .ant-slider-disabled .ant-slider-mark-text {
        cursor: default !important;
        border: none;
      }
    }
  }
  .topTitle {
    background-color: $primary;
    color: white;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
  }
  .more {
    margin: 15px 0;
  }
  .explain {
    background-color: rgb(243, 243, 243);
    padding: 20px;
    margin-bottom: 20px;
  }
}

