@import '../../styles/color.scss';

.contentCard {
  height: 100% !important;
  border-radius: 10px !important;

  .imgDiv {
    position: relative;
    .text {
      font-size: 40px;
      color: $primary;
      font-weight: bold;
      position: absolute;
      width: 100%;
      text-align: center;
    }
    :global {
      .ant-image-img {
        display: block;
        object-fit: contain;
        width: 60px;
        height: 66px;
        // filter: opacity(0.1);
      }
    }
  }
  :global {
    .ant-card-body {
      padding: 8px 16px;
      height: 100%;
    }
    .ant-space {
      height: 100%;
    }
    .ant-typography {
      color: $primary;
      letter-spacing: -0.2px;
    }
  }
}

.contentCard:hover {
  transition: 0.2s;
  border: 1px solid $primary !important;

  // :global {
  //   .ant-image-img {
  //     filter: opacity(1);
  //   }
  //   .ant-typography {
  //     transform: scale(1.01);
  //     transition: 1.1s;
  //   }
  // }
  // .text {
  //   display: none;
  // }
}

.loader {
  width: 100%;
  border-radius: 8px;
  :global {
    .ant-skeleton-input {
      height: 90px !important;
    }
  }
}

