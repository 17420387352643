@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.downloadText {
  :global {
    .ant-btn {
      padding: 0px 0px;
    }
  }
}

.threemapFilterRow {
  margin-top: 30px;
  :global {
    .ant-col-pull-4 {
      right: 16.66666667%;
    }
  }
}

.insuranceMapRow {
  margin-top: 30px;
}

.descLogo {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.desOddLogo {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.greenTextDescription {
  color: $health-green;
}

.blueTextDescription {
  color: $primary;
}

@media (max-width: $tablets) {
  .threemapFilterRow {
    :global {
      .ant-col-pull-4 {
        right: 0%;
      }
      .ant-col-push-4 {
        left: 0%;
      }
      .ant-col-pull-3 {
        right: 0%;
      }
    }
  }
}
