@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.container {
  background-color: rgb(245, 245, 245);
  height: 100%;
  .content {
    padding: 20px;
  }
}
.rate {
  margin-bottom: 15px;
  :global {
    font-size: 14px !important;
    .ant-rate-star-full .ant-rate-star-second {
      background-color: $primary;
      width: 30px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      color: white;
    }
    .ant-rate-star-second {
      background-color: rgb(236, 236, 236);
      color: black;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      width: 30px;
    }
  }
}
.header {
  display: flex;
  justify-content: center;
  height: 48px;
  background-color: $primary;
  align-items: center;
  color: white;
}
.date {
  margin: 5px 0;
}
.calendar {
  margin-bottom: 15px;
}
.title {
  margin-left: 10px;
  font-weight: bold;
}
.minusButton {
  position: absolute;
  right: 20px;
}

