@import '../../../../styles/color.scss';
@import '../../../../styles/fontSizes.scss';
@import '../../../../styles/responsive.scss';

.contentFilterRow {
  background: $grey-card !important;
  :global {
    .ant-card-head {
      background-color: $primary;
      .anticon svg {
        color: white;
        font-size: $lgFontSize;
      }
    }
  }

  .cardheaderText {
    color: white;
    font-size: $standardsFontSize;
    font-weight: 700;
  }
}
