@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';

.filterRow {
  .filter {
    background-color: #f6f6f6 !important;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }
  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #f6f6f6 !important;
      border: none;
    }
    .ant-picker-small {
      background-color: #f6f6f6 !important;
      border-radius: 4px;
      border: none;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  }
}

.results {
  padding: 20px 0;
  color: $normal-blue;
}

.path {
  display: flex;
  align-items: flex-start;
  color: $normal-blue;
}

.home {
  height: 16px;
  margin-right: 5px;
}

.toolsIcon {
  color: $primary;
  font-size: $iconSize;
}

.table {
  cursor: pointer;
}

.profile {
  background-color: white;
  margin: 10px 0;
  padding: 10px 20px;

  .question {
    color: rgb(192, 192, 192);
  }

  .answer {
    color: black;
  }
}

.arrow {
  margin-right: 10px;
}

