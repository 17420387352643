@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.allStatus {
  padding: 10px;
  .status {
    display: flex;
    align-items: center;
    min-height: 36px;
    .icon {
      margin: 0 5px;
      width: 32px;
      font-size: 18px;
    }
  }
}

.reportFormContainer {
  .header {
    display: flex;
    justify-content: right;
  }

  .subHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .reportYear {
      margin: 10px 0;
      color: #8d8d8d;
      :global {
        .ant-picker {
          margin: 0 10px;
        }
      }
    }
  }

  .reportDataTable {
    margin: 15px 0;
    width: 100%;
    overflow: scroll;
    thead {
      tr:first-child {
        background: $primary !important;
      }
      tr:nth-child(2) {
        background: #fff !important;
      }
    }
    th {
      color: white !important;
      background: inherit;
      font-size: 12px !important;
      border: 1px solid #fff !important;
      text-align: center;
      font-weight: 500;
      min-width: 40px;
      white-space: pre-wrap;
      padding: 10px;

      &::before {
        width: 0 !important;
      }
    }
    td {
      white-space: pre-wrap;
    }

    :global {
      .ant-table-row-level-0 {
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
        color: $primary;

        .ant-table-cell {
          padding: 4px 0;
        }
      }

      .ant-table-row-level-1 {
        font-size: 13px;
        white-space: nowrap;
        .ant-table-cell {
          padding: 10px;
          border: 1px solid #fff;
        }
        .anticon {
          font-size: 15px;
        }
        .ant-table-row-indent {
          padding-left: 0 !important;
        }
      }

      td {
        border: 1px solid #fff !important;
        border-bottom-width: 0 !important;
      }

      .ant-table-row-level-1:nth-child(2n) {
        background: #f1f1f152;
      }

      .ant-table-row-expand-icon {
        display: none;
      }
    }
  }

  .infoCard {
    background: rgba(245, 245, 245, 0.534);
    margin-top: 10px;
    padding: 1rem 2rem;
    border-radius: 12px;

    .head{
      padding-bottom: 2rem;
      font-size: 16px;
      font-weight: 700;
    }

    .body{
      padding-bottom: 8px;
      b {
        margin-left: 10px;
      }
    }
  }

}

.orgCard {
  color: white;
  font-weight: bold;
  font-size: $standardsFontSize;
  margin-bottom: 2rem;

  .number {
    font-size: 32px;
    min-width: 40px;
    text-align: center;
    display: inline-table;
  }

  .title {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  :global {
    .ant-divider-vertical {
      border-left: 1px solid white;
      height: 40px;
    }
  }

  .orgCardOne {
    padding: 10px;
    background-color: $primary;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 72px;
    border-radius: 8px;
  }

  .orgCardTwo {
    padding: 10px;
    background-color: #0ebfad;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 72px;
    border-radius: 8px;
  }

  .orgCardThree {
    padding: 10px;
    background-color: #ff5f56;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 72px;
    border-radius: 8px;
  }

  .orgCardFour {
    padding: 10px;
    background-color: #39a6f9;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 72px;
    border-radius: 8px;
  }

  .orgCardFive {
    padding: 10px;
    background-color: #39a6f9;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 72px;
    border-radius: 8px;
  }
}

