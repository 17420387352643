@import '../../../../styles/color.scss';
@import '../../../../styles/fontSizes.scss';

.modalHeader {
  :global {
    .ant-modal-header {
      background-color: $primary !important;
    }
    .ant-modal-title {
      color: $white;
    }
    .anticon {
      color: $white;
      font-size: $iconSize;
    }
  }
}

