@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/responsive.scss';

.videoLink {
  color: $primary;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}

.modal {
  :global {
    .ant-modal-body {
      padding: 0px !important;
    }

    .ant-table-cell .ant-input-search,
    .ant-table-cell .ant-input-search .ant-input-group,
    .ant-table-cell .ant-input-search .ant-input-group .ant-input,
    .ant-table-cell
      .ant-input-search
      .ant-input-group
      .ant-input-search-button {
      border-radius: 5px;
      border: 1px solid #fff !important;
      box-shadow: none !important;
      height: 40px;
      text-align: left;
    }
  }
}

.banner {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  background-color: $grey-card;
}

.img {
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}

