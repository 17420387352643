$primary: #004a9f;
$title-color: #1c3b7a;
$light-grey: #b1b1b1;
$white: white;
$hex: #f75c64;
$blur-blue: #75cfef;
$black: black;
$normal-blue: #1050c7;
$normal-blue1: #0066b3;
$hard-light-grey: #707070;
$yellow: #f0d523;
$hard-green-hex: #00cc97;
$grey-card: #f9f9f9;
$shadow: rgba(0, 0, 0, 0.2);
$health-green: #9ed9b4;
$button-color: $primary;
