@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.tabsDiv {
  justify-content: center;

  :global {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-radius: 0px !important;
      background-color: #fff !important;
      border-color: $primary !important;
      color: $primary !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary !important;
    }
  }
}

.tabsCols {
  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-tab {
      width: 100%;
      place-content: center;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
      display: none;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      place-content: center;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      background-color: $primary;
      color: $white;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      color: $black;
    }

    .ant-tabs-ink-bar {
      background: $primary;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }
  }
}
