@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.siderContent {
  margin: 50px 0;
  :global {
    .ant-table-container,
    .ant-table-container .ant-table-content,
    .ant-table-container .ant-table-content table {
      border-radius: 10px !important;
    }

    .ant-table-footer {
      background: #fff !important;
      border: none !important;
    }
  }
}

.siteLayoutBackgroundMain {
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 10px 40px 0 rgb(62 57 107 / 7%), 0 2px 9px 0 rgb(62 57 107 / 6%);
  padding: 1rem !important;
}

.siteLayoutBackground {
  background: #fff !important;

  :global {
    .ant-table-container,
    .ant-table-container .ant-table-content,
    .ant-table-container .ant-table-content table {
      border-radius: 10px !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: none !important;
      font-weight: bold !important;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: none !important;
    }

    .ant-menu {
      color: $primary !important;
    }

    .ant-layout-sider-zero-width-trigger {
      background: $primary !important;
      color: $white !important;
      top: 10px;
      right: -14px;
      width: 30px;
      height: 32px;
      line-height: 34px;
    }

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
      color: $primary !important;
    }

    .ant-menu-inline .ant-menu-selected::after,
    .ant-menu-inline .ant-menu-item-selected::after {
      display: none !important;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      border: 1px solid #e5e5e5 !important;
      margin: 0 0 2px 0;
      padding: 2rem;
    }

    .ant-table-thead .ant-table-cell {
      border: none !important;
      padding: 8px !important;
    }

    .ant-table-cell {
      text-align-last: left;
    }

    th.textAlignLastCenter {
      text-align-last: center;
    }
  }
}

.siderHeader {
  height: 50px;
  padding-top: 15px;
  background: $primary;
  color: $white;
  text-align-last: center;

  .siderHeaderText {
    color: $white;
  }

  .siderHeaderIcon {
    color: $white;
    font-size: $iconSize;
  }
}

.siderContentSecond {
  min-height: 400px;
  text-align-last: center;
  text-align: -webkit-center;
  width: 100%;
  padding-top: 0 !important;

  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      display: none;
    }
  }
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  line-height: 20px;
  color: #000000d9;
}

@media (max-width: $ipads) {
  .siderContent {
    padding: 0px 0px;
    margin-bottom: 50px;
  }
}

@media (min-width: $lg) {
  .siteLayoutBackground {
    margin-right: 1rem;
  }
}

