@import '../../../../styles/color.scss';
@import '../../../../styles/fontSizes.scss';
@import '../../../../styles/responsive.scss';

.allTable {
  :global {
    .ant-table-thead > tr > th {
      color: $black;
      background: $grey-card !important;
      color: $black;
      line-height: 25px;
    }
    .ant-table-thead > tr > th {
      color: $primary;
      text-align: center;
    }
    .ant-table-tbody > tr > th {
      text-align: center;
    }
    .ant-table-tbody {
      text-align: center;
    }
    .ant-table.ant-table-bordered > .ant-table-footer {
      text-align: end;
      padding: 10px 10px;
    }
  }
}
