@import '../../../../styles/color.scss';

.contentCardCol {
  height: 100% !important;
  // border: 1px solid white !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 5px rgba(214, 213, 213, 0.6);
  :global {
    .ant-typography {
      color: $primary;
    }

    .ant-card-body {
      padding: 10px 22px;
    }

    .ant-image-img {
      display: block;
      width: 70px;
      height: auto;
      filter: opacity(0.1);
    }
  }

  .text {
    font-size: 40px;
    color: $primary;
    font-weight: bold;
    position: absolute;
    top: 14px;
    left: 64px;
  }
}

.contentCardCol:hover {
  transition: 0.2s;
  border: 1px solid $primary !important;

  :global {
    .ant-image-img {
      display: block;
      width: 70px;
      height: auto;
      filter: opacity(1);
    }

    .ant-typography {
      transform: scale(1.01);
      transition: 1.1s;
    }
  }

  .text {
    display: none;
  }
}
