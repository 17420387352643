@import '../../../../styles/color.scss';
@import '../../../../styles/fontSizes.scss';
@import '../../../../styles/responsive.scss';

.companyFilter {
	text-align: -webkit-center;
	padding: 10px 0px;

	.filterInput {
		padding: 2px 0px;
		border-radius: 8px;
	}

	:global {
		.ant-col {
			white-space: nowrap;
		}

		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border: 1px solid white;
			border-radius: 10px 10px 10px 10px;
			height: 43px;
		}

		.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
			height: 41px;
		}

		.ant-btn-primary {
			position: absolute;
			height: 43px;
			right: 63px;
			border-radius: 0px 10px 10px 0px;
		}

		.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
		.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
			text-align: start;
			padding: 5px 11px 9px 10px;
		}
	}
}