@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.companyFilterDiv {
  margin-bottom: 24px;
  padding: 5.5rem 2rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.companyFilterCard {
  :global {
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 12px 10px 10px 10px;
    }

    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .ant-input-group-addon {
      background-color: white;
    }

    .ant-input-group:focus {
      border-color: white;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(136, 233, 87, 0.2);
      box-shadow: 0 0 0 2px rgba(136, 233, 87, 0.2);
    }

    .ant-input-search .ant-input:hover,
    .ant-input-search .ant-input:focus {
      border-color: white;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: white;
    }

    .ant-radio-group {
      width: 100%;
    }

    .ant-radio-checked .ant-radio-inner:focus {
      border-color: $primary;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: $primary !important;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: $primary;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: $primary;
    }

    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      padding: 0px 13px;
    }
  }
}

.smallFilterContainer {
  :global {
    .ant-card {
      border-radius: 2px;
      background: white;
      height: 100%;
    }

    .ant-card-bordered {
      border: 1px solid white;
    }

    .ant-typography {
      font-weight: 500;
      color: $primary;
    }

    .ant-card-body {
      padding: 10px 10px;
    }
  }
}

.headerText {
  color: white !important;
  font-size: $xxlFontSize;
  font-weight: bold;
}
