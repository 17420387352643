@import '../../../styles/color.scss';

.localIndicatorText {
  color: $primary;
}

.filterCardButtonRow {
  margin-top: 20px;
}

.bigCardstyling {
  height: 100%;

  :global {
    .ant-card-body {
      padding: 20px;
      background: $grey-card;
      height: 452px;
    }

    .ant-card-head-title {
      padding: 1px 0px;
    }

    .ant-typography strong {
      color: white;
    }

    .ant-card-extra {
      padding: 0px 0px;
      margin-top: 10px;
    }

    .ant-card-head {
      background: $primary;
      text-align: -webkit-center;
      padding: 0px 20px;
    }

    .anticon svg {
      color: white;
    }

    .ant-select-multiple .ant-select-selection-item {
      background: $primary;
      color: white;
    }
  }
}
