@import '../../../styles/color.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/fontSizes.scss';

.headertext {
  color: $primary;
  font-weight: bold;
}
.relatedtext {
  color: $primary;
  cursor: pointer;
  :global {
    .ant-typography {
      color: $primary !important;
    }
    .ant-divider-horizontal {
      margin: 0px 0px 13px;
    }
  }
}
