@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/responsive.scss';

.headerTitles {
  width: 100%;
  position: absolute;
  top: 26%;
  font-size: $standardsFontSize;
  .title {
    width: 34%;
    font-size: 40px;
    line-height: 40px;
    color: $primary;
  }
  .contentText {
    width: 34%;
    margin-top: 10px;
    background: #ffffff73;
  }
}

@media (max-width: $ipads) {
  .title {
    font-size: 2rem !important;
    line-height: 36px !important;
  }
}

@media (max-width: $tablets) {
  .title {
    font-size: $xlFontSize !important;
    line-height: 24px !important;
  }
}

@media (max-width: $sm) {
  .headerTitles {
    top: 0;
    height: 100%;
  }
}

