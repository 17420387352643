@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.cardTopTitle {
  text-align: center;
  color: $primary !important;
  font-size: $lgFontSize;
  margin-top: 1rem;
  min-height: 75px;
  height: 75px;
  align-self: center;
  display: flex;

  strong {
    width: 100%;
    align-self: center;
  }
}

.leftCardTitle {
  text-align: center;
  color: $normal-blue1 !important;
  font-size: 32px;
  margin: 1rem 0;
}

.boxFeaturedNews {
  text-align: center;
}

.paddingCustome {
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
  border-radius: 4px;
  border: 1.5px solid #e5e5e5;
}

