@import '../../../../styles/color.scss';

.tableActions {
  display: flex;
  justify-content: right;
  margin: 20px 0;
}

.tableWrapper {
  thead tr {
    background: $primary !important;
  }
  th {
    color: white !important;
    background: inherit !important;
    font-size: 12px !important;
    border: 1px solid #fff !important;
    text-align: center;
    font-weight: 100;
    min-width: 40px;
    padding: 10px;
    white-space: pre-wrap;
    &::before {
      width: 0 !important;
    }
  }
  td {
    padding: 4px 8px !important;
    font-size: 12px !important;
  }
  :global {
    .ant-table-body {
      min-height: 150px !important;
    }
    .ant-input,
    .ant-btn,
    .ant-input-number-input,
    .ant-input-number,
    .ant-picker {
      padding: 0;
      border: none;
      box-shadow: none;
      min-width: min-content;
      background: transparent;
      font-size: 12px !important;
      transition: none;
      transform: none;
    }
    .ant-picker-input > input {
      font-size: 12px;
    }
    .ant-table-sticky-holder {
      top: 65px !important;
    }
    .ant-table-cell-scrollbar {
      background: #c7c7c7 !important;
    }
    tr.ant-table-row.ant-table-row-level-0:nth-child(2n) {
      background: #f1f1f152 !important;
    }
  }
}

.fileDownload {
  :global {
    .anticon {
      color: $primary;
      margin-right: 8px;
      font-size: 16px;
    }
  }
}

