@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.governmentSpecialsContentRow {
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  :global {
    .ant-typography strong {
      font-weight: 600;
      color: $primary;
      font-size: $lgFontSize;
    }

    .ant-divider-horizontal {
      border-top: 1.5px solid $primary;
    }

    .anticon svg {
      color: $primary;
    }
  }
}
