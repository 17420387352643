@import '../../../../styles/color.scss';
@import '../../../../styles/fontSizes.scss';
@import '../../../../styles/responsive.scss';

.accountInformationtable {
  margin: 1rem;

  :global {
    .ant-table-thead > tr > th {
      background-color: $primary;
      text-align: center;
      color: $white;
    }

    .ant-table table {
      text-align: center;
    }
  }
}

.tabletext {
  color: $primary;
  cursor: pointer;
}
