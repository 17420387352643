@import '../../../../styles/color.scss';
@import '../../../../styles/fontSizes.scss';

.filterContent {
  background-color: $grey-card !important;
  height: 100%;
  :global {
    .ant-card-body {
      padding: 10px 24px;
    }
    .ant-spin-container {
      min-height: 334px;
    }
  }
  .cardheaderText {
    color: $primary;
    font-weight: bold;
    font-size: $standardsFontSize;
  }
  .contentDiv {
    height: calc(45vh - 10px);
    overflow: auto;
  }

  .spinStyle {
    font-size: $xxlFontSize;
    text-align-last: center;
  }
}

.orgList {
  :global {
    .ant-col,
    .ant-list-item {
      height: 100%;
      margin: 0 !important;
    }
  }
}
