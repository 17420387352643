@import '../../styles/color.scss';

.mobileMenuContainer {
  a {
    color: $primary;
  }
  a:focus,
  a:hover {
    font-weight: bold;
  }
  :global {
    .ant-drawer-header {
      padding: 16px;
    }
    .ant-drawer-body {
      font-weight: normal;
      font-size: 16px;
      background-color: white;
    }
  }
}

