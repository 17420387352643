@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';

.draverDiv {
  :global {
    .ant-drawer-header {
      background-color: $primary !important;
    }
    .anticon.anticon {
      color: $white;
      font-size: 20px;
    }
    .ant-btn-primary {
      background: $primary !important;
      border-color: $primary !important;
    }
  }
}

.drevarHeaderText {
  font-size: 15px;
  color: $white;
}

.draverHeaderIcon {
  font-size: $xlFontSize;
  color: $white;
  margin-top: 8px;
}

