@import '../../../styles/color.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/fontSizes.scss';

.tableContainer {
  display: flex;
  margin: 36px 0;
  .vBar {
    padding: 10px;
    background-color: $primary;
    text-align: center;
  }
  .chartHeader {
    display: flex;
    align-items: center;
    color: $primary;
    font-weight: bold;
    padding-top: 10px;
  }
  :global {
    tr:nth-child(2n) td {
      background-color: $grey-card;
    }
    .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
      text-align: left;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      width: 100%;
    }
    .ant-select {
      width: 100%;
    }
    .ant-table-small .ant-table-thead > tr:nth-child(3n) > th {
      background-color: #e8e8e8;
      color: #112d6a;
      font-weight: bold;
    }
    .ant-table-small .ant-table-thead > tr > th {
      background-color: white;
      font-weight: bold;
      color: $primary;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
}

.buttonBar {
  background-color: $primary;
  padding: 10px;
}

.metaDataTitle {
  color: $primary;
  font-weight: bold;
}

.dataContainer {
  margin-bottom: 50px;
  :global {
    .ant-table-tbody > tr > td {
      height: 70px;
    }
  }
}

.logo {
  margin-left: 20px;
  cursor: pointer;
}

.showTree {
  visibility: visible;
  position: absolute;
  left: 100px;
  top: 20px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem !important;
  min-width: 20vw;
}

@media (max-width: $md) {
  .tableContainer {
    flex-direction: column;
  }
  .vBar {
    :global {
      .ant-space,
      .ant-space-vertical {
        flex-direction: row;
      }
    }
  }
}

