@import '../../../../styles/color.scss';
@import '../../../../styles/responsive.scss';

.menu {
  background-color: $grey-card;
  box-shadow: 0px 1px 5px #a8a8a8;
  min-height: 50vh;
  font-size: 12px;
  .buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 20px;
  }
  :global {
    .ant-steps-item-title {
      font-size: 13px;
    }
  }
}

.filterContent {
  padding: 20px 30px 0 30px;
}

.btn {
  width: 100%;
  justify-content: right;
  padding: 30px;
}

@media (max-width: $lg) {
  .filterContent {
    height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

