@import '../../../styles/color.scss';

.menuBox {
  padding: 10px 20px !important;
  background-color: white;
  height: calc(100% - 30px);
  max-height: 330px;
  width: 100%;
  min-width: 200px;
  overflow-y: scroll;
  font-size: 12px;
  :global {
    .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      font-size: 12px;
    }
    .ant-space {
      width: 100%;
    }
    .ant-space-item:last-child {
      width: 50% !important;
    }
    .ant-skeleton {
      margin: 3px 0;
    }
    .ant-skeleton-element {
      width: 100% !important;
    }
    .ant-skeleton-element > * {
      height: 9px !important;
    }
    .ant-skeleton-input {
      vertical-align: middle !important;
      margin-bottom: 1px;
    }
    .ant-skeleton-avatar {
      width: 15px !important;
      height: 15px !important;
      border-radius: 2px;
    }
  }
}

.subHeader {
  margin-bottom: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  :global {
    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }
}

.row {
  margin: 16px 0;
}

.subMenu {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  :global {
    .ant-tree {
      padding: 10px;
    }

    .ant-checkbox-group-item {
      margin-bottom: 4px;
    }
  }
}

.FilterByOrg {
  .search {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
  }
  .org {
    background-color: #fff;
    display: flex;
    height: 100%;
    border: 1px solid #eee;
    align-items: center;
    padding: 2px 10px;
    :global {
      .ant-image-img {
        max-height: 60px;
      }
    }
  }
  .orgName {
    color: $primary;
    font-weight: 600;
  }
  .label {
    color: $primary;
  }
  .loader {
    color: $primary;
    font-size: 24px;
  }
}

