@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.allCard {
  overflow: auto;
  max-height: 800px;
  .smallNewsParent {
    padding: 0 1rem 1rem 1rem;
    .smallNews {
      color: white;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 280px;
      width: 100%;
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      padding-bottom: 15px;
      .newsTitle {
        background-color: rgba(67, 67, 67, 0.66);
        height: 97px;
        width: 100%;
        padding: 10px 20px;
        font-size: 12px;
        text-align: left;
        display: grid;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;

        .newsDesc {
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 3 !important;
          -webkit-box-orient: vertical !important;
        }
        .more {
          display: flex;
          justify-content: space-between;
          width: 100%;
          float: left;
          font-weight: normal;
        }
      }
      .newsTitle:hover {
        cursor: pointer;
      }
    }
  }
}

.bodyText {
  text-align: justify;
}

.slider {
  font-family: 'slick';
}