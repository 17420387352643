@import '../../styles/color.scss';
@import '../../styles/responsive.scss';
@import '../../styles/fontSizes.scss';

.footerDiv {
  width: 100%;
  height: 100%;
  background-image: url('../../img/newFooter.png');
  background-size: cover;
  margin-top: 50px;
  padding: 3rem 0;
  font-size: 14px;
  :global {
    .ant-image-img {
      width: 80%;
      max-height: 56px;
    }
  }
}

.footerLocationHeader {
  color: $primary;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  :global {
    .ant-typography {
      color: $primary;
    }
  }
}

.footerLocationHeaderIcon {
  color: $blur-blue;
  font-size: $iconSize;
  margin-top: 6px;
}

.contactText {
  p {
    color: $black;
    margin-bottom: 0px;
  }
}

.FooterTexts {
  p {
    color: $black;
  }

  :global {
    .ant-typography {
      color: $black;
    }
    line-height: 2rem;

    .ant-typography:hover {
      cursor: pointer;
      color: $primary;
    }
  }
}

@media (max-width: $tablets) {
  .footerLocationHeader {
    margin-bottom: 0px;
  }
}
