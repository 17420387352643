@import '../../styles/color.scss';
@import '../../styles/responsive.scss';

.headerStyling {
  position: sticky;
  top: 0px;
  z-index: 1000;
  :global {
    .ant-col-3 {
      max-width: 9.2% !important;
      align-self: center;
    }
  }
}

.bigHeader {
  height: auto !important;
  min-height: 40px;
  line-height: 20px;
  background: $primary !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

//navbar
.header {
  display: flex !important;
  line-height: 17px;
  text-align: left !important;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.headerText {
  color: #fff !important;
  font-weight: normal;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: center;
  padding: 21px 16px;
  transition: all 0.2s;
}

.selectedMenu {
  font-weight: 600 !important;
  border-bottom: 3px solid #819aff;
  transition: 0.2s;
}

.selectedMenu,
.headerText:hover,
.headerText:focus {
  box-shadow: 0 8px 32px -12px rgb(0 0 0 / 50%) !important;
  background-color: #ffffff1f !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdowncontent {
  display: none;
  position: absolute;
  background-color: $primary;
  margin-top: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdowncontent a {
  color: white;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.dropdowncontent a:hover {background-color: #ffffff1f; color: #fff}

.dropdown:hover .dropdowncontent {display: block;}

.glossaryDiv {
  :global {
    .ant-btn-dangerous.ant-btn-primarys {
      z-index: 1;
      position: absolute;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      right: 146px;
      bottom: -32px;
      background: red;
      color: white;
      border: none;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 185px;
    }
  }
}

@media (max-width: $tablets) {
  .glossaryDiv {
    :global {
      .ant-btn-dangerous.ant-btn-primarys {
        right: 5rem;
      }
    }
  }
}

@media (max-width: $xs) {
  .glossaryDiv {
    :global {
      .ant-btn-dangerous.ant-btn-primarys {
        right: 0;
      }
    }
  }
}

