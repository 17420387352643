@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.theLawDiv {
  width: 100%;
  overflow: auto;

  :global {
    .ant-table-thead > tr > th {
      color: #3e4473;
      background: #eaecff !important;
      line-height: 25px;
    }

    .ant-table.ant-table-bordered > .ant-table-footer {
      text-align-last: end;
    }

    .ant-table-cell .ant-input-search,
    .ant-table-cell .ant-input-search .ant-input-group,
    .ant-table-cell .ant-input-search .ant-input-group .ant-input,
    .ant-table-cell
      .ant-input-search
      .ant-input-group
      .ant-input-search-button {
      border-radius: 5px;
      border: 1px solid #fff !important;
      box-shadow: none !important;
      height: 40px;
      text-align: left;
    }

    .ant-table-cell
      .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child {
      left: 0 !important;
    }
  }
}

.breadIcon {
  color: $primary;
  font-size: $standardsFontSize;
}

.breadIcons {
  color: $primary;
  font-size: $smFontSize;
}

.breadText {
  color: $primary;
}

.researchRow {
  margin-bottom: 20px;
}
