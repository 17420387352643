@import '../../styles/responsive.scss';

.chatBotImg {
  height: 80px;
  width: 100%;
}

.link {
  display: block;
  position: fixed;
  bottom: 70px;
  left: 10px;
  z-index: 1000;
}

@media (max-width: $sm) {
  .link {
    display: none;
  }
}

