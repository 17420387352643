@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.yearChartTable {
  :global {
    .ant-table-small .ant-table-thead > tr > th {
      background-color: $primary;
      color: white;
      text-align: center;
    }

    .ant-table table {
      text-align: center;
    }

    .ant-table-summary {
      background: $grey-card;
    }
  }
}

.filterRow {
  margin-top: 30px;
  :global {
    .ant-card-body {
      padding: 24px;
      background: $grey-card;
    }
  }
}

.bigCardstyling {
  :global {
    .ant-card {
      background-color: $grey-card;
    }
    .ant-card-body {
      padding: 20px;
      // height: 452px;
    }
    .ant-card-head-title {
      padding: 1px 0px;
    }
    .ant-typography strong {
      color: white;
    }
    .ant-card-extra {
      padding: 0px 0px;
      margin-top: 10px;
    }
    .ant-card-head {
      background: $primary;
      text-align: -webkit-center;
      padding: 0px 20px;
    }
    .anticon svg {
      color: white;
    }
    .ant-select-multiple .ant-select-selection-item {
      background: $primary;
      color: white;
    }
  }
}

.downloadText {
  margin-bottom: 10px;
  :global {
    .ant-btn {
      padding: 0px 0px;
    }
  }
}

.contentRow {
  :global {
    .ant-card {
      height: 100%;
    }
    .ant-card-body {
      padding: 15px 15px;
      background: white;
    }
  }
}

.lightTextColor {
  color: $light-grey !important;
}

.localIndicatorText {
  color: $primary;
  font-size: $standardsFontSize;
}
