@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';

.sideCompanyHeader {
  color: $primary;
  .companyCol {
    justify-content: center;
    text-align: -webkit-center;
    align-items: center;
  }
}
.companyicons {
  color: $primary !important;
  font-size: $iconSize;
}
.isShowHeader {
  font-size: $mdFontSize;
  color: $light-grey !important;
}
.isShowTitles {
  font-size: $smFontSize;
  color: $light-grey !important;
}
.isShowDiv {
  margin-left: 20px;
}
.replyRadioButton {
  padding: 10px;
}
.isAnswersHeader {
  color: $primary;
}
.siderContentSecond {
  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      display: none;
    }
  }
}
.siderContentSecondChild {
  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      display: flex;
    }
  }
}

