@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/responsive.scss';

.mainContent {
  padding-right: 48px;
  .headerDiv {
    display: block;
    text-align: left;
    margin-top: 1rem;
    text-align: justify;
    font-weight: bold;
    color: #212121;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $standardsFontSize;
    position: relative;
  }

  .newsContent {
    .content {
      margin: 1rem 0;
      width: 100%;
      float: left;
      text-align: justify;
    }

    .description {
      margin: 1rem 0;
      width: 100%;
      float: left;
      text-align: justify;
    }
  }
}

.newsList {
  border: 1px solid #f1f1f1;
  padding: 20px;
}

.path {
  display: flex;
  background: #f8f8f8;
  padding: 0.5rem;
  margin-top: 1rem;
  .breadcrumbs {
    font-size: $lgFontSize;
    margin-top: 2px;
    margin-left: 5px;
  }
}

.imgCustome {
  text-align: center;
}

.smallNews {
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 280px;
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  .newsTitle {
    background-color: rgba(67, 67, 67, 0.66);
    height: 97px;
    width: 100%;
    padding: 10px 20px;
    font-size: 12px;
    text-align: left;
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;

    .newsDesc {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 3 !important;
      -webkit-box-orient: vertical !important;
    }

    .more {
      display: flex;
      justify-content: space-between;
      width: 100%;
      float: left;
      font-weight: normal;
    }
  }

  .newsTitle:hover {
    cursor: pointer;
  }
}

