@import '../../../styles/responsive.scss';

.bannerSvg {
  max-width: 100%;
}

.iphone {
  animation: iphone 1.5s ease-in-out infinite alternate;
  transform-origin: bottom;
  transform-box: fill-box;
}
@keyframes iphone {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(30deg);
  }
}

.hand {
  animation: hand 1.5s ease-in-out infinite alternate;
  transform-origin: top left;
  transform-box: fill-box;
}
@keyframes hand {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-10deg);
  }
}

.leg {
  animation: leg 1.5s ease-in-out infinite alternate;
  transform-origin: top left;
  transform-box: fill-box;
}
@keyframes leg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-15deg);
  }
}

.box {
  animation: box 1.5s ease-in-out infinite alternate;
  transform-origin: top left;
  transform-box: fill-box;
}
@keyframes box {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-40%);
  }
}

.head {
  animation: head 1.5s ease-in-out infinite alternate;
  transform-origin: bottom;
  transform-box: fill-box;
}
@keyframes head {
  from {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-5deg);
  }
}

.womanHand {
  animation: womanHand 1.5s ease-in-out infinite alternate;
  transform-origin: top right;
  transform-box: fill-box;
}
@keyframes womanHand {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(25deg);
  }
}

.data1 {
  animation: data 2.5s ease-in-out 1s infinite alternate;
}
.data2 {
  animation: data 2s ease-in-out 2s infinite alternate;
}
.data3 {
  animation: data 1.5s ease-in-out 3s infinite alternate;
}
@keyframes data {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rightHand {
  animation: rightHand 1.5s ease-in-out infinite alternate;
  transform-origin: top;
  transform-box: fill-box;
}
@keyframes rightHand {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(25deg);
  }
}

.leftHand {
  animation: leftHand 1.5s ease-in-out infinite alternate;
  transform-origin: top right;
  transform-box: fill-box;
}
@keyframes leftHand {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(8deg);
  }
}
.paper {
  animation: paper 1.5s ease-in-out infinite alternate;
  transform-origin: top right;
  transform-box: fill-box;
}
@keyframes paper {
  from {
    transform: translateY(0) translateX(0);
  }
  to {
    transform: translateY(-8%) translateX(-8%);
  }
}

@media (max-width: $sm) {
  .bannerSvg {
    max-height: 230px !important;
  }
}

