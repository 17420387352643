@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.theResearchDiv {
  width: 100%;
  overflow: auto;

  :global {
    .ant-table-thead > tr > th {
      color: #3e4473;
      background: #eaecff !important;
      line-height: 25px;
    }
  }
}

.breadIcon {
  color: $primary;
  font-size: $standardsFontSize;
}

.breadIcons {
  color: $primary;
  font-size: $smFontSize;
}

.breadText {
  color: $primary;
}

.researchRow {
  margin-bottom: 20px;
}

.budgetFinanceSearch {
  margin-bottom: 20px;
}

.budgetFinanceText {
  color: $primary;
}

.colPdfStyling {
  color: $primary;
}
