@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.question {
  font-weight: bold;
  font-size: $standardsFontSize;
  color: $black;
  line-height: 32px;
  padding: 0 20px 0 0;
  margin-top: 1rem;
}

.answer {
  font-size: $standardsFontSize;
  text-align: justify;
  line-height: 24px;
  align-items: center;
}

