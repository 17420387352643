@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.device {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
}

.numberCardTotal {
  background-color: $primary;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .totalNumber {
    font-size: 18px;
    font-weight: bold;
  }

  .total {
    font-size: 18px;
  }
}

.cardTopTitle {
  color: $hard-light-grey !important;
  font-size: $mdFontSize;
  min-height: 40px;

  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.leftCardTitle {
  color: $normal-blue1 !important;
  font-size: 18px;
  display: flex;
  min-height: 60px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}

.cardFooter {
  font-size: $mdFontSize;
  :global {
    .ant-rate {
      font-size: 11px !important;
      color: $yellow !important;
      margin-left: 8px;
    }
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: $yellow;
    }
  }
}

.leftCardDivider {
  border: 1px solid #f00 !important;
  width: 80px !important;
  min-width: 50px !important;
  margin: 10px 0px !important;
}

.requestInformation {
  background: $grey-card !important;
  :global {
    .ant-card-body {
      padding: 30px !important;
    }
  }
}

.cardContent {
  cursor: pointer;
  background: white;
  height: 100%;
  padding: 1rem !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px;
  border-radius: 6px;
  border: 1.5px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardContent:hover {
  border: 1.5px solid $normal-blue1 !important;
}

