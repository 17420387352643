@import '../../styles/color.scss';

.stateBudgetTabs {
  margin-top: 60px;
  :global {
    .ant-tabs {
      width: 100%;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: $primary;
      border-start-end-radius: 60px;
      border-top-left-radius: 1px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
      display: none;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      place-content: center;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 80% !important;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
      place-content: center;
      width: 100%;
    }

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-tab {
      width: 100%;
      place-content: center;
      color: $light-grey;
      font-size: 15px;
    }
    .ant-tabs-ink-bar {
      position: absolute;
      background: $primary;
      pointer-events: none;
    }
  }
}

.ulsTEZ {
  height: 60px;
  display: flex !important;
  align-items: center;
  margin-top: 30px;
  color: $primary;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  :global {
    .ant-image {
      margin-right: 8px;
    }
    .ant-image-img {
      max-width: 120px;
      max-height: 60px;
    }
  }
}
