.pvtUi {
  width: 100%;
  display: block;
  overflow: scroll;
  border: 1px solid gainsboro;
}

.pvtVals {
  width: 20%;
}

table.pvtTable {
  width: 100%;
}

.pvtAxisLabel {
  background-color: #d3dcea !important;
}

.pvtTotal,
.pvtTotalLabel,
.pvtGrandTotal {
  /* display: none; */
}

.pvtAxisContainer li {
  padding: 4px;
}

.pvtAxisContainer.pvtVertList li {
  min-width: 300px;
}

.pvtAxisContainer li span.pvtAttr {
  white-space: inherit;
}
