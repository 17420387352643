@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.bigContainer {
  :global {
    .ant-col {
      display: flex;
    }
  }
  .container {
    background-position: center;
    height: 400px;
    width: 100%;
    color: white;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .line {
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }
    .text1 {
      display: flex;
      justify-content: center;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 20px;
    }
    .text2 {
      display: flex;
      justify-content: center;
    }
    .text3 {
      display: flex;
      justify-content: center;
    }
    .text4 {
      display: flex;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
    }
  }
}

@media (max-width: $md) {
  .container {
    height: auto !important;
    padding: 2rem 0;
  }
}

