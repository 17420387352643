@import './styles/color.scss';
@import './styles/responsive.scss';
@import './styles/fontSizes.scss';
@import './styles/ant.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Rubik', sans-serif !important;
}

::-webkit-scrollbar-thumb {
  background: $primary !important;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.line-clamp-2 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.line-clamp-3 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//youtube
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

#ytplayer {
  pointer-events: none;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

//sider

$line-height-sm: 1.5 !default;
$body-color: #9e9e9e !default;

#quickview-customizer {
  width: 410px;
  right: -410px;

  &.quickview-open-customizer {
    right: 0;
  }
}

.customizer {
  padding: 0;
  background-color: $grey-card;

  .quickview-inner {
    padding: 15px 20px;
    height: 100%;
    overflow-y: scroll;
  }

  h4.section-header {
    margin: 12px 0 0;
    font-size: 1rem;
    line-height: $line-height-sm;
    font-weight: normal;
  }

  .customizer-close {
    position: absolute;
    right: 10px;
    top: 1px;
    padding: 7px;
    width: auto;
    z-index: 10;
    font-size: 20px;
    color: $primary;
  }

  a.customizer-toggle,
  a.customizer-close {
    color: $body-color;

    &:hover,
    &:focus {
      color: $body-color;
    }
  }

  .toggle {
    display: flex;
    position: absolute;
    top: 25%;
    left: -90px;
    width: 90px;
    height: 146px;
    background-color: transparent;
    box-shadow: -4px 0 10px $shadow;
    border-radius: 10px 0px 0px 10px;
    transform: rotate(0deg);

    .customizers-toggle {
      text-align: center;
      cursor: pointer;
      color: $primary;
      font-size: 20px;
      display: grid;
      align-items: center;
      width: 100%;
      background-color: $white;

      .one:hover {
        transform: scale(1.2);
        transition: all 0.3s;
      }

      .two:hover {
        transform: scale(1.2);
        transition: all 0.3s;
      }
    }

    .customizerspan-toggle {
      text-align: center;
      cursor: pointer;
      height: 100%;
      background-color: $primary;
      color: $white;
      border-radius: 10px 0px 0px 10px;
      display: flex;
      align-items: center;
      width: 80px;

      p {
        position: absolute;
        font-size: 14px;
        transform: rotate(-90deg);
        margin: 0;
        white-space: nowrap;
        bottom: 0;
        left: 0;
        transform-origin: 0 0;
        margin-left: 10px;
      }
    }
  }
}

$zindex-sticky: 1020 !default;
$l_zindex_navbar: $zindex-sticky;
$l_zindex_quickview: ($l_zindex_navbar + 1);
$l_right_quickview_width: 300px;
$l_white: $white;
$l_transition_ease_out_quickview: cubic-bezier(0.05, 0.74, 0.2, 0.99);
$dark: 'gray-dark';

.quickview-wrapper {
  z-index: $l_zindex_quickview;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  right: -$l_right_quickview_width;
  width: $l_right_quickview_width;
  background: $l_white;
  transition: right 0.4s $l_transition_ease_out_quickview;
  backface-visibility: hidden;

  &.quickview-open {
    right: 0;
  }
}

.quickview-wrapper {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px $shadow;
}

.quickview-wrapper {
  .layout-setting-item {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    font-size: 12px;
    line-height: 22px;

    .ant-switch {
      text-align: center;
    }
  }

  .layout-setting-item-slider {
    margin-top: -5px;
    font-size: 12px;
    line-height: 32px;

    .ant-slider {
      margin-right: 0;
    }
  }

  .ant-radio-button-wrapper {
    padding: 0;

    .layout-opition {
      display: inline-block;
      padding: 0 0.9375rem;
    }
  }
}

.quickview-wrapper {
  .link-animated-hover {
    color: $dark;

    &:after {
      background: $dark;
    }
  }
}

///heaberNav
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  place-content: space-between;
  justify-content: space-evenly;
}

.logo-nav {
  background-color: white !important;
  display: flex;
  width: 100%;
  align-items: center;
  place-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.nav-options {
  display: flex;
  grid-template-columns: repeat(3, auto);
  grid-gap: 40px;
  list-style-type: none;
  margin-bottom: 0px;
  justify-content: space-between;
}

.mobile-menu {
  display: none;
}

@media (max-width: $headerNavbar) {
  /*Mobile View */
  .header {
    padding: 0px 10px;
    place-content: space-between;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .webMenu {
    display: none !important;
  }

  .nav-options {
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .mobile-menu {
    color: white;
    display: flex;
  }

  .menu-icon {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
}

// scrollBar Styling
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.divider-active {
  display: inline;
}

.navs-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.disabled-link {
  pointer-events: none;
}

/////table styling global //////////
.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}

/////mapMongolia///////////////////
.highcharts-text-outline {
  fill: white;
  stroke: white;
  stroke-width: 0px;
  stroke-linejoin: unset;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}

.orgInfo {
  margin-top: 20px;
}

.datePickerCustome .ant-picker-large .ant-picker-input > input {
  font-size: 14px;
}

.datePickerCustome .ant-picker-large {
  border-radius: 5px;
  max-height: 40px;
  font-size: 11px !important;
  padding: 8px 11px;
}

.donutCustome .apexcharts-legend-series {
  padding-bottom: 1rem;
}

.main-title {
  margin-top: 4rem;
  margin-bottom: 3rem;
  text-align: center;
  span {
    color: $primary;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    position: relative;
  }
  span::after {
    position: absolute;
    content: '';
    top: 35px;
    left: 0;
    width: 50px;
    border-radius: 50px;
    height: 5px;
    background: $primary;
  }
}

.sub-title {
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 24px;
  color: $primary;
  text-align: center;
  align-items: center;
}

.season {
  background-color: #98c8ff36 !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

//banner
.lazy-load-image-background.blur {
  height: 700px;
}

.banner-img {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.carousel-left > path:last-child,
.carousel-right > path:last-child {
  display: none;
}

.carousel-left {
  position: absolute;
  top: 35%;
  z-index: 1;
  font-size: 60px;
  color: $title-color;
  margin-left: 40px;
}

.carousel-right {
  position: absolute;
  top: 35%;
  z-index: 1;
  right: 1px;
  font-size: 60px;
  color: $title-color;
  margin-right: 40px;
}

.banner {
  position: absolute;
  top: 0;
  margin: 0 10%;
}

.banner-title {
  color: $title-color;
  font-size: 2.5rem;
  line-height: 40px;
  text-overflow: ellipsis;
  font-weight: 700;
  max-width: 600px;
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}

.banner-sub-title {
  color: $title-color;
  font-size: $standardsFontSize;
  font-weight: 700;
}

.banner-desc,
.banner-desc-link {
  color: $title-color;
  max-width: 800px;
  padding: 10px;
  background: #004a9f2e;
  transition: all 0.2s;
  margin-bottom: 2rem;
}

.banner-desc-link {
  cursor: pointer;
  &:hover {
    background-color: #004a9f3b;
  }
}

.icon {
  color: $primary;
  font-size: $iconSize;
}

@media (max-width: $headerNavbar) {
  .lazy-load-image-background.blur {
    max-height: 330px;
    height: auto;
  }
  .carousel-left,
  .carousel-right {
    top: 150px;
  }
  .navs-options {
    display: none !important;
  }
  .divider-active {
    display: none;
  }
}

@media (max-width: $ipads) {
  .banner-title {
    font-size: 2rem;
    max-width: 400px;
  }
}

@media (max-width: $tablets) {
  .banner {
    margin: 0 2rem;
  }
  .banner-title {
    font-size: $xxlFontSize;
    max-width: 300px;
    line-height: 30px;
    margin-bottom: 1.5rem;
  }
  .carousel-left {
    margin-left: 1rem;
  }
  .carousel-right {
    margin-right: 1rem;
  }
  .ant-carousel .slick-prev {
    font-size: 30px !important;
    color: $white !important;
    left: 196px !important;
    display: none !important;
  }

  .ant-carousel .slick-next {
    font-size: 30px !important;
    color: $white !important;
    right: 196px !important;
    display: none !important;
  }

  .left {
    display: none;
  }

  .right {
    display: none;
  }

  #quickview-customizer {
    width: 320px;
    right: -320px;

    &.quickview-open-customizer {
      right: 0;
    }
  }

  .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10;
    font-size: 1px;
    color: $primary;
  }

  a.customizer-toggle,
  a.customizer-close {
    color: $body-color;

    &:hover,
    &:focus {
      color: $body-color;
    }
  }
}

@media (max-width: $sm) {
  .banner-img {
    display: none !important;
  }
  .banner {
    position: static;
  }
  .carousel-left,
  .carousel-right {
    display: none;
  }
  .toggle {
    left: -40px !important;
  }
  .quickview-open-customizer .toggle {
    left: -90px !important;
  }
}
