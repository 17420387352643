@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';

.resultCard {
  :global {
    .ant-card-body {
      padding: 0;
    }
  }
}

.nameOrg {
  margin: 40px 0px;

  :global {
    .ant-typography {
      cursor: pointer;
    }
  }
}

.logoOrganization {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  height: auto;
  width: 100%;
}

.logoOrganization::after {
  content: '';
  padding-bottom: 100%;
  display: block;
}

.blue {
  color: $normal-blue;
}

.orgMainInfo {
  background: $grey-card;
  padding: 1rem;
  overflow-wrap: anywhere;
  border-radius: 16px;
}

.label {
  font-weight: bold;
  color: $black;
  margin-bottom: 8px;
}

.contact {
  margin: 16px 0;
  .labelContact {
    font-weight: bold;
    color: $black;
  }
}

.orgName {
  font-size: 18px;
  color: $primary;
  font-weight: 700;
  line-height: 26px;
}

.testtable {
  margin-left: 30px;

  :global {
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      text-align: center;
    }

    .ant-table-thead > tr > th {
      font-weight: bold;
    }
  }
}

.resultIndicatorsToolsIcon {
  color: $primary;
  font-size: $iconSize;
}
