@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.chartContainer {
  margin-top: 40px;
  .title1 {
    font-size: 20px;
    font-weight: bold;
    color: $primary;
  }
  .title2 {
    font-size: 40px;
    font-weight: bold;
    color: $primary;
    line-height: 48px;
    margin: 20px 0;
  }
  .first {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid rgb(211, 211, 211);
    border-right: 1px solid rgb(211, 211, 211);
    padding: 20px;
  }
  .second {
    border-bottom: 1px solid rgb(211, 211, 211);
  }
  .third {
    border-right: 1px solid rgb(211, 211, 211);
    padding-right: 20px;
  }
}

//InvestmentSearch
.searchContainer {
  margin-top: 20px;
  :global {
    .ant-btn {
      padding: 0px !important;
    }
  }
  .angilal {
    width: 250px;
    background-color: rgb(245, 245, 245);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .angilalOptions {
      width: 140px;
    }
  }
  .filterContainer {
    background-color: rgb(245, 245, 245);
    width: 250px;
    .subHeader {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      height: 48px;
      background-color: $primary;
      align-items: center;
      color: white;
    }
    .filter {
      padding: 20px;
      .subFilter {
        margin-bottom: 15px;
      }
      .row {
        margin-top: 15px;
      }
      .rate {
        margin-bottom: 15px;
        :global {
          font-size: 14px;
          .ant-rate-star-full .ant-rate-star-second {
            background-color: $primary;
            width: 30px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            color: white;
          }
          .ant-rate-star-second {
            background-color: rgb(236, 236, 236);
            color: black;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            width: 30px;
          }
        }
      }
      .btnClear {
        width: 100px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        border: 2px solid $primary;
        color: $primary;
      }
      .btnFilter {
        width: 100px;
        display: flex;
        justify-content: space-around;
        border-radius: 10px;
        border: 2px solid $primary;
      }
    }
  }
  .chart {
    background-color: rgb(250, 250, 250);
  }
}

.options {
  width: 100%;
}

//InvestmentLocation
.locationContainer {
  margin-top: 20px;
  .map {
    border: 1px solid rgb(211, 211, 211);
  }
}

