@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/responsive.scss';

.headerTitles {
  width: 100%;
  position: absolute;
  top: 120px;
  font-size: $standardsFontSize;
  .headerTitles48 {
    width: 12%;
    font-size: 40px;
    line-height: 40px;
    color: $primary;
  }
  .headerTitlesText {
    margin-top: 10px;
  }
}

.bannerContainer {
  position: relative;
  margin-top: 20px;
  :global {
    .ant-carousel .slick-dots li.slick-active button {
      background-color: $primary;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .ant-carousel .slick-dots li button {
      background-color: $normal-blue;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

.cardContainer {
  margin-top: 20px;
  .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
    height: 150px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: white;
    .title {
      position: absolute;
      text-align: center;
      margin: 0px 20px;
      opacity: 0;
      transition: 0.3s;
    }
    .logo {
      opacity: 1;
      transition: 0.3s;
    }
  }
  .card:hover {
    .title {
      opacity: 1;
      transition: 0.3s;
    }
    .logo {
      opacity: 0.3;
      transition: 0.3s;
      transform: scale(1.7);
    }
  }
  .active {
    .title {
      opacity: 1;
      transition: 0.3s;
    }
    .logo {
      opacity: 0.3;
      transition: 0.3s;
      transform: scale(1.7);
    }
  }
  .a {
    background-color: $normal-blue1;
  }
  .b {
    background-color: #ffca61;
  }
  .c {
    background-color: #50b9ff;
  }
  .d {
    background-color: #6ab189;
  }
}

.options {
  width: 100%;
}
.path {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}
.endPath {
  color: $normal-blue;
  font-weight: bold;
}
.home {
  height: 16px;
  margin-right: 5px;
}

