@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/responsive.scss';

.downloadText {
  :global {
    .ant-btn {
      padding: 0px 0px;
    }
    .anticon svg {
      color: $primary;
      font-size: $iconSize;
    }
  }
}

.bannerContainer {
  min-height: 300px;
  position: relative;
  :global {
    .ant-carousel .slick-dots li.slick-active button {
      background-color: $primary;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -150px;
      border-radius: 50%;
    }
    .ant-carousel .slick-dots li button {
      background-color: $normal-blue;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -150px;
      border-radius: 50%;
    }
  }
  .info {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.85);
    .title24 {
      color: $primary;
      font-size: 24px;
      font-weight: bold;
      margin: 12px 0;
    }
    .title20 {
      font-size: 20px;
    }
    .description {
      color: $primary;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.popoverHead {
  font-size: 16px;
  color: $primary;
  display: flex;
  flex-direction: row;
  .popoverTitle {
    width: 40%;
  }
}

.cardContainer {
  margin-top: 60px;
  .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
    height: 110px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    .cardName {
      color: white;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      line-height: 20px;
    }
    .cardItem {
      display: flex;
      justify-content: center;
    }
  }
  .a {
    background-color: #e6e6e6;
    .cardName {
      color: #023378;
    }
  }
  .b {
    background-color: rgb(37, 139, 112);
  }
  .c {
    background-color: rgb(18, 146, 174);
  }
  .d {
    background-color: rgb(222, 179, 105);
  }
}

.calendarContainer {
  margin-top: 80px;
  .calendar {
    min-height: 450px;
    display: flex;
    align-items: center;
    transition: 0.5s;
  }
  .calendarDe {
    min-height: 200px;
    display: flex;
    align-items: center;
    transition: 0.5s;
  }
  .calendarItem {
    color: white;
    font-weight: bold;
    font-size: 18px;
    .active {
      margin-left: 29px;
      .item {
        height: auto;
        padding: 0 10px 0 20px;
        opacity: 1;
        color: black;
        font-size: 14px;
        font-weight: normal;
        transition: 0.5s;
      }
      .i1 {
        border-left: 2px dashed $primary;
      }
      .i2 {
        border-left: 2px dashed #567b8e;
      }
      .i3 {
        border-left: 2px dashed #c39f7f;
      }
      .i4 {
        border-left: 2px dashed #00b8d5;
      }
      .bullet {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-left: -3px;
      }
      .b1 {
        background-color: $primary;
      }
      .b2 {
        background-color: #567b8e;
      }
      .b3 {
        background-color: #c39f7f;
      }
      .b4 {
        background-color: #00b8d5;
      }
    }
    .deactive {
      margin-left: 29px;
      .item {
        height: 0;
        padding: 0 10px 0 20px;
        border-left: 2px dashed #ffffff;
        opacity: 0;
        color: black;
        font-size: 14px;
        font-weight: normal;
        transition: 0.5s;
      }
    }
  }
  .calendarItem1 {
    cursor: pointer;
    z-index: 10;
    background-color: $primary;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .calendarItem2 {
    cursor: pointer;
    z-index: 10;
    background-color: #567b8e;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .calendarItem3 {
    cursor: pointer;
    z-index: 10;
    background-color: #c39f7f;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .calendarItem4 {
    cursor: pointer;
    z-index: 10;
    background-color: #00b8d5;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow {
    margin-left: 18px;
  }
  :global {
    .ant-table-small .ant-table-thead > tr > th {
      background-color: #ececec;
      color: $primary;
      text-align: center !important;
      font-weight: bold;
      border-right: none !important;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      text-align: center !important;
      border-collapse: separate !important;
      border-spacing: 0px 50px !important;
    }
  }
}

@media (max-width: $headerNavbar) {
  .bannerContainer {
    .info {
      position: static;
    }
  }
}

//popOver style
.popOverStyle {
  width: 230px;
  text-align: center;
  padding: 20px 20px;
}
.popOverDiv {
  text-align: end;
  .popOverIcon {
    color: $primary;
    font-size: $iconSize;
  }
}

.logo {
  margin-bottom: 1rem;
}

//tableStyling

.allTable {
  border: 10px solid #f0f0f0;
  :global {
    .ant-table-small .ant-table-thead > tr > th {
      background-color: $primary;
      color: white;
      text-align: center;
    }
    .ant-table table {
      text-align: center;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td {
      border-right: none !important;
    }
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      padding: none !important;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 10px solid #f0f0f0;
    }
  }
}

@media (max-width: $ipads) {
  .row {
    display: none;
  }
}
