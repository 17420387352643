@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.allSpace {
  margin: 20px 0px;
  overflow: auto;
}

.filterIcon {
  color: $hex;
}

.filterRow {
  margin-bottom: 40px;
  :global {
    .ant-btn-link {
      color: black;
    }
  }
}

@media (max-width: $tablets) {
  .filterRow {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
