@import './color.scss';

.ant-carousel .slick-prev {
  font-size: 30px !important;
  color: $white !important;
  left: 196px !important;
}

.ant-carousel .slick-next {
  font-size: 30px !important;
  color: $white !important;
  right: 196px !important;
}

.ant-btn-primary {
  background-color: $button-color !important;
  border-color: $button-color !important;
  &:hover,
  &:focus {
    background-color: lighten($button-color, 15%) !important;
  }
}

.ant-tree-switcher-loading-icon {
  color: $primary !important;
}

