@import '../../styles/color.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/responsive.scss';

.headerTitles {
  font-size: $standardsFontSize;
  padding-bottom: 80px;
  padding-top: 30px;
}

.svgCol {
  text-align: center;
}

.bannerContainer {
  // min-height: 500px;
  position: relative;
  background-image: url('../../img/openData/opendatabanner.png');
  background-size: cover;
  :global {
    .ant-carousel .slick-dots li.slick-active button {
      background-color: $primary;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -10px;
      border-radius: 50%;
    }
    .ant-carousel .slick-dots li button {
      background-color: $normal-blue;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -10px;
      border-radius: 50%;
    }
  }
}

.menuContainer {
  margin-top: 10px;
  .outerCard {
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    // &::after {
    //   position: absolute;
    //   left: 16px;
    //   height: 16px;
    //   width: 16px;
    //   background-color: inherit;
    //   border-radius: 50%;
    //   content: '';
    // }
  }

  .card {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    background-color: #fff;
    border-radius: 10px;
    color: $primary;
    min-height: 60px;
    padding: 4px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 4px 4px 12px;
    }
  }

  .activeCard {
    color: #fff;
    background-color: inherit;
    padding: 4px;
    z-index: 1;
    &:hover {
      background-color: inherit;
    }
  }
}

.tableContainer {
  margin: 50px 0;
  th {
    border: 1px solid #fff !important;
    &::before {
      width: 0 !important;
    }
  }
  thead > tr:nth-child(2n) > th {
    background-color: #e8e8e8;
    color: #112d6a;
    font-weight: bold;
  }
  :global {
    tr:nth-child(2n) td {
      background-color: $grey-card;
    }
    .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
      text-align: left;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      width: 100%;
    }
    .ant-select {
      width: 100%;
    }
    .ant-table-cell {
      border: 1px solid #fff;
    }
  }
}

.searchInput {
  width: 100%;
  border: none;
  padding: 0;
  color: black;
  background-color: inherit;
  input {
    background-color: inherit;
  }
  :global {
    .anticon {
      font-size: 20px;
      color: $primary;
    }
  }
}

.resultContainer {
  margin-top: 50px;
}

.content {
  margin-top: 50px;
  display: flex;
  .menuSide {
    width: 120px;
  }
  .result {
    width: 100%;
  }
  .reportName {
    font-weight: bold;
    font-size: $standardsFontSize;
    color: white;
    margin-right: 20px;
  }
  .filter {
    background-color: $primary;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding: 20px 40px;
    .filterIcon {
      margin-left: 1rem;
    }
  }
  .activeMenu {
    background-color: $primary !important;
    color: white;
  }
  .menu {
    background-color: #f5f5f5;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 13px;
    cursor: pointer;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.openDataLink {
  color: $primary;
  cursor: pointer;
}

@media (min-width: 1600px) {
  .menuContainer {
    :global {
      .ant-col-xxl-4 {
        flex: auto;
        max-width: 20%;
      }
    }
  }
}

@media (max-width: $lg) {
  .content {
    flex-direction: column;
  }
  .menuSide {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
}

