@import '../../../styles/color.scss';

.switchIcon {
  :global {
    .anticon svg {
      cursor: pointer;
      color: $primary;
      font-size: 22px;
    }

    .ant-btn {
      padding: 0px 0px;
    }
  }
}

.headerColumnChartSecond {
  padding-bottom: 20px;
  :global {
    .ant-select {
      color: white !important;
      background-color: $primary;
    }

    .ant-select-arrow {
      color: white !important;
    }

    .ant-picker {
      color: white !important;
      background: $primary;
    }

    .ant-picker-suffix {
      color: white !important;
    }

    .ant-picker-input > input {
      color: white !important;
    }

    .ant-radio-checked .ant-radio-inner:focus {
      border-color: $primary;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: $primary !important;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: $primary;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: $primary;
    }

    .ant-radio-inner {
      width: 18px;
      height: 18px;
    }
  }
}

.headerCardRow {
  border-radius: 10px !important;
  box-shadow: 1px 1px 5px rgba(214, 213, 213, 0.6);
  :global {
    .ant-card-body {
      padding: 40px;
      background-color: $grey-card;
    }
  }
}

.headerColumnChart {
  :global {
    .ant-select {
      color: white !important;
      background-color: $primary;
    }

    .ant-select-arrow {
      color: white !important;
    }

    .ant-picker {
      color: white !important;
      background: $primary;
    }

    .ant-picker-suffix {
      color: white !important;
    }

    .ant-picker-input > input {
      color: white !important;
    }

    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: $primary;
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: $primary;
    }

    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: $primary;
      border-color: $primary;
    }
  }
}
