@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.downloadText {
  :global {
    .ant-btn {
      padding: 0px 0px;
    }
  }
}

.localIncomeToolsIcon {
  color: $primary;
  font-size: $iconSize;
}

.TabsCol {
  margin-top: 30px;
  :global {
    .ant-tabs-nav .ant-tabs-tab-active,
    div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: $primary;
      border-start-end-radius: 60px;
      border-top-left-radius: 1px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $white;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      place-content: center;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100% !important;
    }
    .ant-tabs-nav .ant-tabs-tab,
    div > .ant-tabs-nav .ant-tabs-tab {
      place-content: center;
      width: 100%;
    }
  }
}

.localIncometable {
  :global {
    .ant-table-thead > tr > th {
      text-align: center;
      font-weight: 600;
    }
  }
}

.localIncomeTableFooterText {
  color: $light-grey;
  margin-top: 30px;
}
