@import '../../../styles/fontSizes.scss';

.featuredMenuDiv {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  background-size: cover;
  text-align: center;
  .featuredMenuHeader {
    padding-top: 3rem;
    padding-bottom: 1rem;
    .featuredMenuHeaderText {
      color: white;
      font-size: $xxlFontSize;
      position: relative;
    }
    .featuredMenuHeaderText::after {
      position: absolute;
      content: '';
      top: 35px;
      left: 0;
      width: 50px;
      border-radius: 50px;
      height: 5px;
      background: #fff;
    }
  }
}
.featuredMenuRow {
  margin-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  :global {
    .ant-card-bordered {
      border: 1px solid #bdcefa52 !important;
      border-radius: 10px !important;
      box-shadow: 1px 1px 10px rgba(65, 65, 65, 0.6);
      width: 100%;
      max-height: 200px;
      display: flex;
      align-items: center;
    }
    .ant-card-bordered img {
      max-width: 100%;
      max-height: 200px;
      position: relative;
    }
    .ant-card-body {
      width: 100%;
      display: flex;
      padding: 10px !important;
      span {
        color: #fff;
        text-align: center;
        font-size: 19px;
        align-self: center;
        line-height: 24px;
        font-weight: 700;
      }
    }
    .ant-col:last-child {
      padding-right: 0 !important;
    }
  }
}
.featuredMenuCardshover:hover {
  transform: scale(0.97);
  transition: 0.5s;
  cursor: pointer;
  background: #bdcefa !important;
}
.featuredMenuCardshover {
  transition: 0.5s;
  height: 206px;
  width: 130px;
  background: #bdcefa52 !important;
}

