@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.container {
  margin-top: 10px !important;
  :global {
    .ant-card-body {
      padding: 0;
    }
  }
}

.threemapToolsIcon {
  color: $primary;
  font-size: $iconSize;
}

.toolsIcon {
  color: $primary;
  font-size: $iconSize;
}

.threemapTabsCol {
  padding: 0px !important;
  :global {
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: $primary;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $white;
    }
    .ant-table-small .ant-table-thead > tr > th {
      font-weight: bold;
      text-align: center;
    }
    .ant-table-tbody > tr > td {
      text-align: right;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      text-align: center !important;
    }
  }
}
.logos {
  margin-top: 20px;
  margin-right: 20px;
}

