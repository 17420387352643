@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/responsive.scss';

.budgetFinanceText {
  color: $primary;
  width: 170px;
}

.breadIcon {
  color: $primary;
  font-size: $standardsFontSize;
}
.breadIcons {
  color: $primary;
  font-size: $smFontSize;
}
.breadText {
  color: $primary;
}
.researchRow {
  margin-bottom: 20px;
}
.budgetFinanceImage {
  align-self: center;
}
.budgetFinanceHeader {
  width: 100%;
  background-color: $primary;
  color: $white;
  padding: 10px;
  text-align: start;
  align-items: start;
}
.budgetFinanceHeadeText {
  color: $white !important;
}
.budgetFinanceSearch {
  margin-bottom: 20px;
}
.colPdfStyling {
  color: $primary;
}
.footerSettings {
  :global {
    .ant-list-header,
    .ant-list-footer {
      position: absolute;
      right: 10px;
    }
  }
}

@media (max-width: $xs) {
  .footerSettings {
    :global {
      .ant-card-body {
        padding: 8px !important;
      }
    }
  }
}
