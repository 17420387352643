@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.breadIcon {
  color: $primary;
  font-size: $standardsFontSize;
}
.breadIcons {
  color: $primary;
  font-size: $smFontSize;
}
.breadText {
  color: $primary;
}

.allRow {
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}
.headerCol {
  justify-content: start;
  text-align: start;
  align-items: center;
  .secondCol {
    .smallText {
      font-size: 10px;
      text-align-last: start;
      color: $primary;
    }
  }
}
.commentPic {
  cursor: pointer;
}
.bigDiv {
  height: calc(79vh - 312px);
  overflow: auto;
  width: 100%;
  .secondCol {
    height: 100%;
    width: 100%;
  }
  .sectionRow {
    align-items: center;
    .sectionImage {
      max-height: 120px;
      width: 90%;
      max-width: 200px;
    }
    .secondCol {
      justify-content: start;
      text-align: start;
      text-align-last: start;
    }
    .secondText {
      font-size: $standardsFontSize;
      text-align-last: start;
    }
    .text {
      font-size: 10px;
    }
    .commentCol {
      justify-content: start;
      text-align: start;
    }
  }
}
