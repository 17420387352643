@import '../../../styles/color.scss';
@import '../../../styles/fontSizes.scss';

.allSpaces {
  margin-top: 30px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 1rem;
  height: 100%;
}

.headerDiv {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;

  .text {
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $standardsFontSize;
    position: relative;
  }
}

.filterIcon {
  color: $hex;
}

.filterRow {
  margin-top: 20px;
  margin-bottom: 10px;

  :global {
    .ant-btn-link {
      color: black;
    }
  }
}

