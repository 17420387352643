@import '../../../../styles/color.scss';

.contentDiv {
  padding: 20px;
  :global {
    .ant-typography strong {
      color: $primary;
    }
    .ant-skeleton-element {
      width: 100%;
      height: 90px;
    }
    .ant-skeleton-input {
      height: 100% !important;
      border-radius: 10px;
    }
  }
}

.contentCardCol {
  :global {
    .ant-card.ant-card-bordered {
      font-size: 16px;
      height: 100% !important;
      border: 1px solid white !important;
      border-radius: 10px !important;
      box-shadow: 1px 1px 5px rgba(214, 213, 213, 0.6);
    }

    .ant-typography {
      color: $primary;
      min-height: 59px;
      display: block;
    }

    .ant-card-body {
      padding: 10px 22px;
    }

    .ant-image-img {
      display: block;
      width: 70px;
      height: auto;
      filter: opacity(0.1);
    }
  }

  .text {
    font-size: 40px;
    color: $primary;
    font-weight: bold;
    position: absolute;
    top: 14px;
    left: 64px;
  }
}

.contentCardCol:hover {
  transition: 1.1s;

  :global {
    .ant-card.ant-card-bordered {
      height: 100% !important;
      border: 1px solid $primary !important;
      border-radius: 10px !important;
      box-shadow: 1px 1px 5px rgba(214, 213, 213, 0.6);
    }

    .ant-image-img {
      display: block;
      width: 70px;
      height: auto;
      filter: opacity(1);
    }

    .ant-typography {
      color: $primary;
      font-size: 17px;
      transform: scale(1.06);
      transition: 1.1s;
    }
  }

  .text {
    display: none;
  }
}

.branchOrganizationsCollapsed {
  :global {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: $primary !important;
      color: $white !important;
    }
  }
}

.branchOrganizationsButton {
  cursor: pointer;
}

.branchOrganizationsTabsBodyHeader {
  color: $primary;
}

.comp {
  width: 200px;
  height: 100px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  box-shadow: 0px 2px 5px rgba(214, 213, 213, 0.6);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 1px 2px -2px rgba(214, 213, 213, 0.6),
      0px 3px 6px 0px rgba(214, 213, 213, 0.6),
      0px 5px 12px 4px rgba(214, 213, 213, 0.6);
  }
}
